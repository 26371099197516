import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

export default function ServiceCard({
  last,
  title,
  description,
  image,
  reverse,
}) {
  return (
    <div className=" md:h-[25rem] xl:h-[24rem] bg-white grid lg:flex lg:justify-between sm:border-[0.5px] sm:border-primary/20">
      <div className={`h-56 lg:h-full lg:w-2/5 ${reverse ? "lg:order-2" : ""}`}>
        {image?.childImageSharp ? (
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            className="w-full object-cover h-full"
            alt={title}
          />
        ) : (
          <img src={image} alt={title} className="w-full object-cover h-full" />
        )}
      </div>
      <div
        className={`p-4 md:p-8 flex flex-col gap-4 lg:w-3/5 ${
          reverse ? "lg:order-1" : ""
        }`}
      >
        <div className=" flex-grow">
          <h3 className="text-stone-900 text-3xl font-serif truncate">
            {title}
          </h3>
          <p
            className="text-stone-900 text-sm"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        <div className="pt-12">
          <Link
            to="/book-online"
            className="px-8 py-3 bg-secondary text-primary uppercase trakcing-widest text-xs"
          >
            Book Now
          </Link>
        </div>
      </div>
    </div>
  )
}
