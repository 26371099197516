import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Header from "../components/section/header"
import ServiceCard from "../components/section/ServiceCard"
import Seo from "../components/seo"
export default function Service({ data, location }) {
  // console.log(data)
  return (
    <Layout>
      <Seo title="Services" pathname={location.pathname} />
      <Header title="Services" />
      <Content services={data.services.nodes} />
    </Layout>
  )
}

const Content = ({ services }) => {
  return (
    <section className="overflow-hidden py-8 md:py-0">
      <div className="w-11/12 md:w-full mx-auto grid gap-8 md:gap-0 md:grid-cols-2">
        {services.map((service, key) => {
          const frontmatter = service.frontmatter
          return (
            <ServiceCard
              key={key}
              {...frontmatter}
              last={key === services.length - 1}
              reverse={key % 2 ? true : false}
            />
          )
        })}
      </div>
    </section>
  )
}

export const query = graphql`
  query {
    services: allMarkdownRemark(
      filter: {
        fields: { collection: { eq: "services" } }
        frontmatter: { publish: { eq: true } }
      }
      sort: { order: ASC, fields: frontmatter___id }
    ) {
      nodes {
        frontmatter {
          title
          publish
          image
          description
        }
      }
    }
  }
`
